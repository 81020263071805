import "/assets/styles/sections/header/header.scss";
import PropTypes from "prop-types";
import { useState, useRef, useEffect } from "react";
import loadable from "@loadable/component";
import kebabCase from "lodash/kebabCase";

// Hooks
import useVisible from "pstv-commerce-tools/hooks/visible";

// Controllers
import { useBasket } from "/controllers/basket";

// Context
import { useNavigator } from "pstv-commerce-tools/utilities/navigator";
import { useModals } from "pstv-commerce-tools/utilities/modals";
import { useAuth } from "pstv-commerce-tools/utilities/auth";
import { useBreakpoints } from "pstv-commerce-tools/utilities/breakpoints";
import { useGlobalState } from "pstv-commerce-tools/utilities/global-state";

// Partials
import Btn from "/views/partials/btn";
import Loader from "/views/partials/loader";
import Placeholder from "/views/partials/placeholder";
import Icon from "pstv-commerce-tools/partials/icon";
import Link from "pstv-commerce-tools/partials/link";
import Img from "pstv-commerce-tools/partials/img";
import SearchForm from "./partials/search-form";

// Static Assets
import image_logo from "/assets/images/logo.svg";

// Sections
const AddressBar = loadable(() => import("./sections/address-bar"), {
    ssr: false,
    fallback: <Placeholder className="header-addressbar" />,
});
const MobileSearch = loadable(() => import("./sections/mobile-search"), {
    ssr: false,
});
const MobileMenu = loadable(() => import("./sections/mobile-menu"), {
    ssr: false,
});

// Static Assets
import image_icon_discount from "/assets/images/categories/icon-discount.svg";
import image_icon_new from "/assets/images/categories/icon-new.svg";

const Header = ({ plain }) => {
    const { openModal } = useModals();
    const { activeRoute, redirect } = useNavigator();
    const { loggedIn, userData } = useAuth();
    const { desktop } = useBreakpoints();
    const {
        summary: basketSummary,
        busy: basketBusy,
        initialized: basketInitialized,
    } = useBasket();

    const [showMobileSearch, setShowMobileSearch] = useState(false);
    const [showMobileMenu, setShowMobileMenu] = useState(false);

    const goBack = () => {
        redirect(activeRoute.previousPage ?? "home");
    };

    useEffect(() => {
        if (desktop) {
            setShowMobileSearch(false);
            setShowMobileMenu(false);
        }
    }, [desktop]);

    return (
        <>
            <header className={`section header${plain ? " plain" : ""}`}>
                <div className="header-main-placeholder" />
                <div className="header-main">
                    <div className="wrapper main-wrap">
                        {plain && (
                            <button
                                className="main-backbtn"
                                type="button"
                                onClick={goBack}
                            >
                                <Icon name="angle-left" /> Alışverişe Devam Et
                            </button>
                        )}
                        {!desktop && !plain && (
                            <div className="main-mobilenav">
                                <button
                                    onClick={() => {
                                        setShowMobileMenu(!showMobileMenu);
                                    }}
                                    className="mobilenav-btn mobilenav-menubtn"
                                >
                                    <Icon name="menu" />
                                </button>
                                <button
                                    onClick={() => {
                                        setShowMobileSearch(!showMobileSearch);
                                    }}
                                    className="mobilenav-btn mobilenav-searchbtn"
                                >
                                    <Icon name="search" />
                                </button>
                            </div>
                        )}
                        <Link href="home" className="main-logo">
                            <Img src={image_logo} className="logo-image" />
                        </Link>
                        {!plain && desktop && <CategoryMenu />}
                        {!plain && desktop && <Search />}
                        {!plain && desktop && (
                            <nav className="main-nav">
                                <Link className="nav-item" href="campaigns">
                                    <Icon name="campaign" />
                                    Kampanyalar
                                </Link>
                                <Link
                                    onClick={(e) => {
                                        if (!loggedIn) {
                                            e.preventDefault();
                                            openModal("auth");
                                        }
                                    }}
                                    className="nav-item"
                                    href="account"
                                >
                                    <Icon name="user" />
                                    {loggedIn
                                        ? `Merhaba ${userData?.name}`
                                        : "Giriş Yap"}
                                </Link>
                            </nav>
                        )}
                        {!plain && (
                            <Link
                                href="basket"
                                type="button"
                                className="main-basketbtn btn secondary"
                            >
                                <Icon className="basketbtn-icon" name="cart" />
                                <span className="basketbtn-total loader-container">
                                    <Loader
                                        solid
                                        loading={
                                            (!basketInitialized && loggedIn) ||
                                            basketBusy
                                        }
                                    />
                                    {basketSummary.grand_total.currency_symbol}{" "}
                                    {/* {(
                                        Number(
                                            basketSummary.grand_total.original
                                        ) + Number(deliveryFee)
                                    ).toFixed(2)} */}
                                    {basketSummary.grand_total.original_str}
                                </span>
                            </Link>
                        )}
                        {plain && (
                            <div className="main-securebadge">
                                Güvenli Alışveriş <Icon name="secure" />
                            </div>
                        )}
                    </div>
                </div>
                {activeRoute.showSlogan && <Slogan />}
                {!activeRoute?.hideAddressBar && <AddressBar />}
            </header>
            {!desktop && (
                <>
                    <MobileSearch
                        onClose={() => {
                            setShowMobileSearch(false);
                        }}
                        show={showMobileSearch}
                    />

                    <MobileMenu
                        onClose={() => {
                            setShowMobileMenu(false);
                        }}
                        show={showMobileMenu}
                    />
                </>
            )}
        </>
    );
};

const Search = () => {
    const { redirect } = useNavigator();

    const [searchVal, setSearchVal] = useState("");
    const inputChanged = (e) => {
        setSearchVal(e.target.value);
    };

    const submitSearch = (e) => {
        e.preventDefault();
        if (searchVal.length > 1) {
            redirect("search", { search: searchVal });
            setSearchVal("");
        }
    };

    return (
        <SearchForm
            className="main-search"
            value={searchVal}
            onChange={inputChanged}
            onSubmit={submitSearch}
        />
    );
};

const CategoryMenu = () => {
    const { categories } = useGlobalState();

    const containerRef = useRef();

    const [show, setShow] = useState(false);
    const [inDom, visible] = useVisible(show);

    const containerBlurEvent = (e) => {
        if (containerRef.current) {
            if (
                e.target === containerRef.current ||
                containerRef.current.contains(e.target)
            ) {
                setShow(false);
            }
        }
    };

    // Custom List - Vegan Products
    const veganSubCategory = {
        name: "Vegan Ürünler",
        id: process.config.staticCustomLists?.veganProducts.id,
        slug: process.config.staticCustomLists?.veganProducts.slug,
        list_image:
            "https://cdn.metro-group.com/tr/metroonlinevegan17112023.png", // Vegan Products CDN
    };

    const modifiedCategories = [...(categories || []), veganSubCategory];

    return (
        <div
            className="main-categorymenu"
            onBlur={containerBlurEvent}
            ref={containerRef}
        >
            <Btn
                className="categorymenu-btn secondary"
                onClick={() => {
                    setShow(!show);
                }}
                afterIcon="menu"
            >
                Kategoriler
            </Btn>

            {inDom && (
                <div
                    className={`categorymenu-dropdown${visible ? " show" : ""}`}
                >
                    <div className="dropdown-categories">
                        {modifiedCategories.map((category) => (
                            <div
                                className="categories-category"
                                key={category.id}
                            >
                                <Link
                                    href={
                                        category.id !== 23
                                            ? "category"
                                            : "customList"
                                    } // Manual check for veganProducts
                                    onClick={() => {
                                        setShow(false);
                                    }}
                                    className="category-link"
                                    params={{
                                        id: category.id,
                                        slug:
                                            category.slug ??
                                            kebabCase(category.name),
                                    }}
                                >
                                    <div className="category-imagewrap">
                                        <Img
                                            className="imagewrap-image"
                                            src={category.list_image}
                                        />
                                    </div>
                                    <strong className="category-title">
                                        {category.name}
                                    </strong>
                                </Link>
                            </div>
                        ))}
                    </div>
                    <div className="dropdown-highlights">
                        {process.config.staticCustomLists
                            ?.discountedProducts && (
                            <Link
                                href="customList"
                                className="highlights-link"
                                params={{
                                    id: process.config.staticCustomLists
                                        ?.discountedProducts.id,
                                    slug: process.config.staticCustomLists
                                        ?.discountedProducts.slug,
                                }}
                            >
                                <Img
                                    className="link-image"
                                    src={image_icon_discount}
                                />
                                <span>İndirimli Ürünler</span>
                            </Link>
                        )}
                        {process.config.staticCustomLists?.newProducts && (
                            <Link
                                href="customList"
                                className="highlights-link"
                                params={{
                                    id: process.config.staticCustomLists
                                        ?.newProducts.id,
                                    slug: process.config.staticCustomLists
                                        ?.newProducts.slug,
                                }}
                            >
                                <Img
                                    className="link-image"
                                    src={image_icon_new}
                                />
                                <span>Yeni Ürünler</span>
                            </Link>
                        )}
                    </div>
                </div>
            )}
        </div>
    );
};

const Slogan = () => {
    const { homeSlogan } = useGlobalState();

    if (homeSlogan) {
        return (
            <div className="header-slogan">
                <div
                    className="wrapper"
                    dangerouslySetInnerHTML={{ __html: homeSlogan }}
                />
            </div>
        );
    } else {
        return null;
    }
};

Header.propTypes = {
    plain: PropTypes.bool,
};

export default Header;
