import PropTypes from 'prop-types';

// Partials
import Btn from '/views/partials/btn';
import Icon from 'pstv-commerce-tools/partials/icon';

const SearchForm = ({ className = '', value, onChange, onSubmit, ...props }) => {
	return (
		<form className={`header-searchform ${className}`} onSubmit={onSubmit}>
			<Icon className="search-icon" name="search" />
			<input
				{...props}
				type="text"
				placeholder="Ürün ara"
				className="search-input"
				value={value}
				onChange={onChange} />
			<Btn type="submit" className="search-submitbtn rounded" icon="arrow-right" />
		</form>
	)
}

SearchForm.propTypes = {
	value: PropTypes.string,
	className: PropTypes.string,
	onChange: PropTypes.func,
	onSubmit: PropTypes.func,
}

export default SearchForm;
