import '/assets/styles/sections/footer.scss';
import { useMemo } from 'react';
import PropTypes from 'prop-types';
import kebabCase from 'lodash/kebabCase'
import loadable from '@loadable/component';

// Data
import agreementSlugs from '/data/agreement-slugs';

// Context
import { useGlobalState } from 'pstv-commerce-tools/utilities/global-state';
import { useBreakpoints } from 'pstv-commerce-tools/utilities/breakpoints';
import { useNavigator } from 'pstv-commerce-tools/utilities/navigator'

// Partials
import Placeholder from '/views/partials/placeholder'
import Collapser from '/views/partials/collapser';
import Img from 'pstv-commerce-tools/partials/img';
import Link from 'pstv-commerce-tools/partials/link';
import Icon from 'pstv-commerce-tools/partials/icon';

// Static Assets
import image_payment_mastercard from '/assets/images/payment/mastercard.svg'
import image_payment_visa from '/assets/images/payment/visa.svg'
import image_payment_amex from '/assets/images/payment/amex.svg'

// Lazy Components
const EtbisLogo = loadable(() => import('../partials/etbis-logo'), { ssr: false, fallback: <Placeholder id="ETBIS" /> });

const Footer = ({ plain }) => {
	const { categories } = useGlobalState();
	const { mobile } = useBreakpoints();
	const { routes } = useNavigator();

	const splitCategories = useMemo(() => {
		if(mobile) {
			return [categories || [false, false, false, false, false, false]];
		}
		if(!categories) {
			return [[false, false, false, false, false], [false, false, false, false]];
		}
		const midIndex = Math.ceil(categories.length / 2);

		return[categories.slice().splice(0, midIndex), categories.slice().splice(-midIndex)];

	}, [categories, mobile])

	return (
		<footer className={`section footer${plain ? ' plain' : ''}`}>
			{!plain &&
				<div className="footer-nav">
					<div className="wrapper nav-wrap">
						<div className="nav-group wide">
							<NavGroup title="Kategoriler" wide>
								{splitCategories.map((categoryGroup, gnth) => (
									<ul className="group-items" key={`catg-${gnth}`}>
										{categoryGroup.map((category, nth) => (
											<li className="items-item" key={category?.id ?? `footer-category-${gnth}-${nth}`}>
												{category ?
													<Link
														className="item-link"
														href="category"
														params={{
															id: category.id,
															slug: category.slug ?? kebabCase(category.name)
														}}>
														{category.name}
													</Link>
													:
													<Placeholder className="item-link" />
												}
											</li>
										))}
									</ul>
								))}
							</NavGroup>
						</div>
						{/* <div className="nav-group">
							<NavGroup title="Kampanyalar">
								<ul className="group-items">
									{campaigns.map((campaign, nth) => (
										<li className="items-item" key={`camp-${nth}`}>
											{campaign ?
												<span className="item-link">
													{campaign.name}
												</span>
												:
												<Placeholder className="item-link" />
											}
										</li>
									))}
								</ul>
							</NavGroup>
						</div> */}
						<div className="nav-group">
							<NavGroup title="Hesabım">
								<ul className="group-items">
									{Object.keys(routes.account.children).map((routeKey) => {
										if(routes.account.children[routeKey].hideFromMenu === true) {
											return false;
										}
										else {
											return (
												<li className="items-item" key={routeKey}>
													<Link className="item-link" route={routes.account.children[routeKey]} />
												</li>
											)
										}
									})}
								</ul>
							</NavGroup>
						</div>
						<div className="nav-group">
							<NavGroup title="Kurumsal">
								<ul className="group-items">
									<li className="items-item">
										<Link
											raw
											className="item-link"
											href="https://www.metro-tr.com/"
											target="_blank"
											rel="noopener noreferrer">
											Kurumsal Bilgiler <Icon name="link-external" />
										</Link>
									</li>
									<li className="items-item">
										<Link
											className="item-link"
											href="faqs">
											Sıkça Sorulan Sorular
										</Link>
									</li>
								</ul>
							</NavGroup>
						</div>
						<div className="nav-group etbis">
							<div className="etbis-wrap">
								<EtbisLogo />
							</div>
						</div>
					</div>
				</div>
			}
			<div className="footer-mid">
				<div className="wrapper mid-wrap">
					{process.config.contact.customerServicesNumber &&
						<a className="mid-call" href={`tel:${process.config.contact.customerServicesNumber}`} title="Bizi Arayın">
							Bizi Arayın <Icon name="call" /> <span>{process.config.contact.customerServicesNumberPretty ?? process.config.contact.customerServicesNumber}</span>
						</a>
					}

					<div className="mid-brands">
						<Img
							className="brands-brand"
							style={{
								width: "2.6rem",
								height: "1.6rem",
							}}
							src={image_payment_mastercard}
							alt="Mastercard" />
						<Img
							className="brands-brand"
							style={{
								width: "5.2rem",
								height: "1.6rem",
							}}
							src={image_payment_visa}
							alt="Visa" />
						<Img
							className="brands-brand"
							style={{
								width: "2.6rem",
								height: "2.6rem",
							}}
							src={image_payment_amex}
							alt="American Express" />
					</div>

					<nav className="mid-social">
						<a
							className="social-link"
							href="https://tr-tr.facebook.com/Metrotoptancimarket?_ga=2.226296282.1986307825.1649541039-1309710355.1649541039"
							target="_blank"
							rel="noopener noreferrer"
							title="Bizi Facebook üzerinden takip edin.">
							<Icon name="facebook" />
						</a>
						<a
							className="social-link"
							href="https://twitter.com/metrotr?_ga=2.51248745.1986307825.1649541039-1309710355.1649541039"
							target="_blank"
							rel="noopener noreferrer"
							title="Bizi Twitter üzerinden takip edin.">
							<Icon name="twitter" />
						</a>
						<a
							className="social-link"
							href="https://www.instagram.com/metroturkiye/?hl=tr&_ga=2.51248745.1986307825.1649541039-1309710355.1649541039"
							target="_blank"
							rel="noopener noreferrer"
							title="Bizi Instagram üzerinden takip edin.">
							<Icon name="instagram" />
						</a>
						<a
							className="social-link"
							href="https://www.youtube.com/channel/UChjhiwSJtHUmU1dTHd5o_1A?_ga=2.51248745.1986307825.1649541039-1309710355.1649541039"
							target="_blank"
							rel="noopener noreferrer"
							title="Bizi YouTube üzerinden takip edin.">
							<Icon name="youtube" />
						</a>
					</nav>
				</div>
			</div>
			<div className="footer-bottom">
				<div className="wrapper bottom-wrap">
					<div className="bottom-copyright">
						© METRO Türkiye 2022
					</div>

					<nav className="bottom-nav">
						<Link
							href="agreement"
							params={{ slug: agreementSlugs['usage_terms'] }}
							title="Kullanım Koşulları">
							Kullanım Koşulları
						</Link>
						<Link
							href="agreement"
							params={{ slug: agreementSlugs['kvkk'] }}
							title="KVKK Aydınlatma Metni">
							KVKK Aydınlatma Metni
						</Link>
						<Link
							href="agreement"
							params={{ slug: agreementSlugs['cookie'] }}
							title="Çerezler">
							Çerezler
						</Link>
						<Link
							href="agreement"
							params={{ slug: agreementSlugs['consent'] }}
							title="Açık Rıza Metni">
							Açık Rıza Metni
						</Link>
						<a
							href="https://www.belgemodul.com/sirket/1311?_ga=2.23569786.1506481537.1652644890-1309710355.1649541039"
							title="Bilgi Toplumu Hizmetleri">
							Bilgi Toplumu Hizmetleri
						</a>
					</nav>
				</div>
			</div>
		</footer>
	)
}

Footer.propTypes = {
	plain: PropTypes.bool,
}

const NavGroup = ({ wide = false, children, title }) => {
	const { desktop } = useBreakpoints();

	if(desktop) {
		return (
			<div className={`nav-group${wide ? ' wide' : ''}`}>
				<strong className="group-title">{title}</strong>
				{children}
			</div>
		)
	}
	else {
		return (
			<Collapser className="nav-group" title={title}>
				{children}
			</Collapser>
		)
	}
}

NavGroup.propTypes = {
	title: PropTypes.string,
	wide: PropTypes.bool,
	children: PropTypes.node,
}

export default Footer
